.App {
  display: flex;
  flex-direction: column;
}

/*make main-content to 2 col using flex. width will  be left 60% right 40%*/
.main-content {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.logs {
  background-color: rgba(246, 246, 246, 1);
  display: flex;
  flex-direction: column;
}

.logs>label {
  margin-left: 10px;
  margin-top: 2px;
  font-size: 16px;
}

.bg-grey {
  background-color: rgba(246, 246, 246, 1);
  position: relative;
}

.bg-grey>.eyes {
  position: absolute;
  top: 5px;
  right: 25px;
  width: 25px;
  height: 25px;
  /* padding: 5px; */
}

.blur {
  filter: blur(4px);
}